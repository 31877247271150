table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 2px solid rgb(140 140 140);
    font:
      16px "Open Sans",
      Helvetica,
      Arial,
      sans-serif;
  }
  th,
  td {
    border: 1px solid rgb(160 160 160);
    padding: 4px 6px;
  }
  
  th {
    vertical-align: bottom;
  }


  